/*############### GOOGLE FONTS ###############*/
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

/*############### VARIABLES CSS ###############*/
:root {
  --header-height: 3.5rem;

  /*########## Colors ##########*/
  --first-color: #0078ff;
  --secund-color: #6100ff;
  --first-color-alt: #;
  --title-color: #001128;
  --text-color: #445161;
  --body-color: #fff;
  --white-color: #fff;
  --blue-light: #e9f5fe;
  --container-color: #081a32;

  --dark-color: #001128;

  --border-color: #eaeaea;

  /*Green gradient*/
  --linear-color: linear-gradient(136deg, #2d84ff, #0069fd);

  /*########## Font and typography ##########*/
  --body-font: "Inter", sans-serif;
  --title-font: "Plus Jakarta Sans", sans-serif;
  --biggest-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.875rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*########## Font weight ##########*/
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-black: 900;

  /*########## z index ##########*/
  --z-tooltip: 10;
  --z-fixed: 20;
  --z-modal: 100;
}

/* Responsive typography */
@media screen and (min-width: 1024px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*############### BASE ###############*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-color);
  background-color: var(--body-color);
}

h1,
h2,
h3,
h4 {
  font-weight: 800;
  color: var(--title-color);
  font-family: var(--title-font);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button,
input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

/*############### SWIPER CSS ###############*/
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
}

.section2__content-swiper .swiper-slide-active {
  background: #082854;
}

.section2__content-swiper .swiper-slide-prev,
.section2__content-swiper .swiper-slide-next {
  opacity: 0.3;
}

.swiper-button-prev,
.swiper-button-next {
  position: initial;
  width: 44px;
  height: 44px;
  background-color: var(--container-color);
  border-radius: 50%;
  color: #fff;
  font-size: 1.25rem;
}

.arrows__buttons {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;
  justify-content: center;
  margin-top: 2rem;
}

/*############### REUSABLE CSS CLASSES ###############*/
.section {
  padding: 4.5rem 0 2rem;
}

.section__subtitle {
  background-color: var(--blue-light);
  color: var(--first-color);
  padding: 0.875rem;
  border-radius: 5rem;
  text-align: center;
  justify-self: center;
  margin-bottom: 1.25rem;
  font-weight: 600;
}

.fixed-width {
  width: 150px;
}

.section__title {
  font-size: var(--h1-font-size);
  font-weight: 800;
  margin-bottom: 1rem;
  text-align: center;
}

.text__linear {
  background: var(--linear-color);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.container {
  max-width: 1192px;
  margin-inline: 1.5rem;
}

.grid {
  display: grid;
}

.main {
  overflow: hidden; /*For animation*/
}

.circle__shadow {
  position: absolute;
  background-color: var(--secund-color);
  z-index: 1;
  border-radius: 50%;
}

/*############### HEADER AND NAV ###############*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-fixed);
  background-color: transparent;
  transition: background 0.3s;
}

.nav {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__logo {
  width: 72px;
}

.nav__toggle {
  cursor: pointer;
  font-size: 1.25rem;
  display: inline-flex;
  align-items: center;
  color: var(--white-color);
}

/*############### MOBILE DEVICE ###############*/
@media screen and (max-width: 1024px) {
  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 75%;
    height: 100%;
    background-color: var(--white-color);
    padding: 6rem 0 2rem 2.5rem;
    transition: right 0.4s;
    z-index: var(--z-modal);
    box-shadow: -4px 0px 16px 0 rgba(3, 30, 60, 0.055);
  }
}

.nav__close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--title-color);
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 2rem;
}

.nav__link {
  color: var(--text-color);
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--first-color);
}

/* Show menu */
.show-menu {
  right: 0;
}

/* Change background header */
.scroll-header {
  background-color: var(--white-color);
  box-shadow: 0 4px 16px 0 rgba(3, 30, 60, 0.055);
}

.scroll-header .nav__toggle {
  color: var(--title-color);
}

.scroll-header .nav__logo {
  mix-blend-mode: difference;
}

/* Active link */

/* Swiper Class */

/*############### BUTTONS ###############*/
.button {
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 5rem;
  background-color: var(--first-color);
  transition: background 0.3s;
  color: var(--white-color);
  font-weight: var(--font-medium);
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
  background-color: #0C2F56;
  z-index: -1;
  transition: .5s;
  border-radius: 150% 150% 0 0;
}

.button:hover::before {
  height: 100%;
  border-radius: 0 0 0 0;
}

.button:hover {
  color: #fff;
}

.header-button {
  display: none;
}

/*############### HERO ###############*/
.hero {
  overflow: hidden;
  position: relative;
  background-color: var(--dark-color);
  width: 100%;
  height: 100vh;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

.hero__glass-shadown {
  position: absolute;
  width: 100%;
  height: 30%;
  border-radius: 0rem 0rem 7.5rem 7.5rem;
  background: linear-gradient(180deg, rgba(0, 17, 40, 0.00) 0%, #001128 100%);
  bottom: 0;
  left: 0;
  z-index: var(--z-tooltip);
}

.hero__container {
  padding-top: 6rem;
  row-gap: 2.5rem;
}

.hero__data {
  text-align: center;
  z-index: 2;
}

.hero__title {
  color: var(--white-color);
  font-size: var(--biggest-font-size);
  font-weight: 800;
  font-family: var(--title-font);
  margin-bottom: 1rem;
}

.hero__description {
  margin-bottom: 2rem;
  color: #a5b3c5;
  line-height: 21px;
}

.hero__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--dark-color);
  border: 1px solid #2b4371;
  border-radius: 5rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.hero__input-button {
  font-size: 0.875rem;
  padding: 1rem 1.25rem;
}

.hero__input {
  background-color: transparent;
  width: 55%;
  font-family: var(--body-color);
  font-size: 0.875rem;
  color: #a5b3c5;
}

.hero__input::placeholder {
  color: #a5b3c5;
}

.hero__logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  justify-self: center;
}

.hero__logo {
  width: 100px;
  margin: 0 1.25rem;
}

.hero__image {
  width: 360px;
  justify-self: center;
  z-index: 2;
}

.hero__shadow-1 {
  width: 300px;
  height: 300px;
  filter: blur(120px);
  top: -6rem;
  left: -6rem;
  opacity: 0.5;
}

.hero__shadow-2 {
  width: 350px;
  height: 350px;
  filter: blur(120px);
  bottom: 2rem;
  right: -10rem;
  opacity: 0.3;
}

/*############### EXPLORE ###############*/
.explore__container {
  row-gap: 2.5rem;
}

.explore__data {
  text-align: center;
}

.explore__tabs {
  display: grid;
  row-gap: 1rem;
}

.explore__tab {
  font-family: var(--body-font);
  border-radius: 5rem;
  font-weight: var(--font-medium);
  transition: .3s;
  padding: 1rem 2rem;
}

.active-tab {
  background-color: var(--first-color);
  color: #fff;
}

.active-tab h4 {
  color: #fff;
}

.explore__tab:hover {
  background-color: var(--first-color);
  color: #fff;
}

.explore__description {
  line-height: 21px;
  color: var(--text-color);
}

.explore__image {
  justify-self: center;
}

.explore__content {
  row-gap: 2.5rem;
  margin-bottom: 2.5rem;
  display: none;
  animation: moving 0.5s ease-out;
}

.explore__content.active {
  display: grid;
}

@keyframes moving {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.explore__content-infos {
  row-gap: 2.5rem;
}

.explore__infos-content {
  background-color: #edf4fe;
  padding: 1.25rem;
  border-radius: 1.25rem;
}

.explore__infos-title {
  font-size: var(--h2-font-size);
  margin-bottom: 1rem;
}

.explore__infos-description {
  color: var(--text-color);
  margin-bottom: 1.25rem;
  line-height: 21px;
}

.explore__infos-link {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  color: var(--first-color);
}

.explore__infos-link i {
  transition: transform 0.3s;
}

.explore__infos-link:hover i {
  transform: translateX(0.25rem);
}

/* .explore__content-tabs {
  display: none;
} */

.explore__content-tab {
  padding: 1.25rem 1rem;
  border-radius: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.explore__tab-text {
  font-size: 1rem;
}

/*############### WORKS ###############*/
.works__container {
  row-gap: 2.5rem;
  padding-bottom: 4rem;
}

.works__data {
  text-align: center;
}

.works__description {
  color: var(--text-color);
  line-height: 21px;
}

.works__content {
  row-gap: 2rem;
}

.works__card {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 2rem;
}

.works__card-img {
  margin-bottom: 1.25rem;
}

.works__card-title {
  margin-bottom: 0.8rem;
  font-size: 1.25rem;
}

.works__card-description {
  line-height: 21px;
}

/*############### OVERVIEW ###############*/
.overview {
  position: relative;
  background-color: var(--dark-color);
  border-radius: 40px;
  margin-bottom: 5rem;
  padding-bottom: 4rem;
}

.overview__container {
  row-gap: 4.5rem;
  padding: 2rem 0 3rem;
}

.section__mask-1,
.section__mask-2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(0deg);
  width: 220px;
  z-index: 1;
}

.section__mask-1 {
  top: -0.25rem;
}

.section__decoration-icon-1,
.section__decoration-icon-2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(0);
  background-color: var(--white-color);
  box-shadow: 0 12px 50px 0 #04172e13;
  width: 52px;
  height: 52px;
  display: grid;
  place-items: center;
  z-index: 2;
  border-radius: 50%;
}

.section__decoration-icon-1 {
  top: -3rem;
}

.section__mask-2 {
  transform: translateX(-50%) rotate(180deg);
  bottom: -0.25rem;
}

.section__decoration-icon-2 {
  transform: translateX(-50%) rotate(180deg);
  bottom: -2rem;
}

.section__decoration-icon-1 i,
.section__decoration-icon-2 i {
  font-size: 1.25rem;
  background: var(--linear-color);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.overview__section1 {
  row-gap: 2.5rem;
}

.overview__section1-data {
  text-align: center;
}

.dark-theme {
  color: var(--white-color);
}

.dark__subtitle {
  background-color: #031E3C;
}

.works__description {
  color: #a5b3c5;
  line-height: 21px;
}

.overview__section1-content {
  row-gap: 2.5rem;
}

.overview__section1-tabs {
  row-gap: 1.25rem;
}

.section1__tabs {
  order: 1;
}

.section1__tab {
  position: relative;
  padding: 1.25rem 2.5rem;
}

.section1__tab::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #263448;
  left: 0;
  top: 0;
}

.section1__tab-title {
  color: var(--white-color);
  font-size: var(--h2-font-size);
  margin-bottom: 0.5rem;
}

.section1__tab-categorie {
  color: #a5b3c5;
}

.overview__section1-image {
  position: relative !important;
  background-color: #031e3c;
  padding: 1rem 1rem 0 1rem;
  overflow: hidden;
  border-radius: 2rem;
  margin-bottom: 2.5rem;
  display: none;
  animation: moving 0.5s ease-out;
}

.overview__section1-image.active {
  display: initial;
}

@keyframes moving {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.section1__content-grid {
  order: 2;
}

.overview-active-tab.section1__tab::after {
  background-color: #fff;
}

.overview__section1-img {
  position: relative;
  width: 250px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.overview-1-decoration {
  position: absolute;
  width: 150px !important;
  right: -1rem;
  top: 5rem;
}

.section1__image-play {
  cursor: pointer;
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #31313136;
  backdrop-filter: blur(24px);
  color: var(--white-color);
  display: grid;
  place-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 1.25rem;
}

/* Overview 2 */
.overview__section2 {
  row-gap: 2.5rem;
}

.section2__rate {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  justify-content: center;
}

.section2__content-swiper {
  padding-bottom: 5rem;
}

.section2__rate-images {
  display: flex;
  align-items: center;
}

.section2__rate-images img {
  width: 32px;
  border-radius: 50%;
}

.section2__rate-images img:nth-child(2) {
  margin-left: -1rem;
}

.section2__rate-images img:nth-child(3) {
  margin-left: -1rem;
}

.section2__rate-images img:nth-child(4) {
  margin-left: -1rem;
}

.section2__rate-images img:nth-child(5) {
  margin-left: -1rem;
}

.section2__rate-images img:nth-child(6) {
  margin-left: -1rem;
}

.section2__rate-description {
  color: #a5b3c5;
}

.section2__card {
  background: var(--container-color);
  padding: 1.5rem;
  border-radius: 1.5rem;
  width: 250px;
  color: #fff;
  height: 330px;
}

.section2__card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section2__card-user img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.section2__card-user {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.section2__card-user h4 {
  color: var(--white-color);
  font-weight: 600;
}

.section2__card-quote {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.section2__card-text {
  line-height: 21px;
  margin-bottom: 1.5rem;
}

.section2__rate-stars {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.section2__rate-stars i {
  color: #ffd027;
}

/* Section 3 */
.overview__section3 {
  border-radius: 2rem;
  padding: 1.25rem;
  row-gap: 2.5rem;
  border: 1px solid #404D5E;
}

.section3__data .section__title {
  text-align: initial;
}

.section3__data-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  margin-top: 2rem;
  width: 180px;
}

.section3__button {
  background-color: #fff;
  color: var(--title-color);
}

.button-ghost {
  background: none;
  border: 1px solid #404D5E;
}

.section3__chart {
  justify-self: center;
}

.section3__chart img {
  width: 250px;
}

/* Section 4 */
.overview__section4 {
  row-gap: 2.5rem;
}

.section4__data {
  text-align: center;
}

.section4__card {
  border-radius: 1.5rem;
  background-color: #031e3c;
  overflow: hidden;
}

.section4__card-image img {
  transition: .4s;
}

.section4__card-flex .section4__card-image {
  height: 200px;
  overflow: hidden;
}

.section4__card-image {
  position: relative;
  overflow: hidden;
}

.section4__card:hover .section4__card-image img {
  scale: 1.2;
  cursor: pointer;
}

.section4__small-cards {
  margin-bottom: 2rem;
}

.card-1 {
  margin-bottom: 2rem;
}

.section4__card-padding {
  padding: 1rem 1rem 1.5rem 1rem;
}

.section4__card-tag {
  padding: 0.25rem 0.5rem;
  color: var(--first-color);
  background-color: #062b4e;
  border-radius: 5rem;
  margin-bottom: 1rem;
  width: 100px;
  display: flex;
  justify-content: center;
}

.section4__card-title {
  color: var(--white-color);
  margin-bottom: 2rem;
  font-size: var(--h3-font-size);
}

.section4__card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section4__card-user {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.section4__card-user img {
  width: 32px;
  height: 32px !important;
  border-radius: 50%;
}

.section4__card-user p {
  color: var(--white-color);
}

.section4__card-data {
  color: #a5b3c5;
  font-size: .8rem;
}

/*############### FOOTER ###############*/
.footer__logo {
  margin-bottom: 1.25rem;
}

.footer__logo img {
  width: 100px;
  mix-blend-mode: difference;
}

.footer__social-links {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-top: 1.25rem;
}

.footer__social-link {
  font-size: 1.25rem;
  color: var(--title-color);
  transition: color 0.3s;
}

.footer__social-link:hover {
  color: var(--first-color);
}

.footer__infos {
  margin-bottom: 2rem;
}

.footer__item-title {
  margin-bottom: 1rem;
}

.footer__content {
  row-gap: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--border-color);
}

.footer__item-list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.footer__item-link {
  color: var(--title-color);
  transition: color 0.3s;
}

.footer__item-link:hover {
  color: var(--first-color);
}

.footer__copy {
  display: block;
  font-size: var(--smaller-font-size);
  margin: 0 auto;
  text-align: center;
  padding: 3rem 0 0 0;
}

/*############### SCROLL UP ###############*/
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background: var(--first-color);
  display: inline-flex;
  padding: 0.5rem;
  border-radius: 0.45rem;
  font-size: 1.25rem;
  color: #fff;
  z-index: var(--z-modal);
  box-shadow: 0px 4px 12px 0px #00112815;
  transition: all 0.4s;
}

/* Show Scroll Up*/
.show-scroll {
  bottom: 3rem;
}

/*############### SCROLL BAR ###############*/
::-webkit-scrollbar {
  width: 0.6rem;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0.5rem;
}

/*############### CURSOR ###############*/
#cursor {
  position: fixed;
  z-index: var(--z-modal);
  pointer-events: none; /* assim nao mostra a letra */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  width: 0.875rem;
  height: 0.875rem;
  background-color: var(--first-color);
  transition: all 0.1s, left 0s, right 0s;
}

/*###############  KEYFRAMES ###############*/
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/*###############  BREAKPOINTS ###############*/
/* For small devices */
@media screen and (max-width: 320px) {
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .hero__container,
  .explore__data,
  .explore__content,
  .works__container {
    grid-template-columns: 400px;
    justify-content: center;
  }

  .overview__section3 {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
  }

  .works__content {
    grid-template-columns: 280px;
    justify-content: center;
  }

  .footer__content {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4rem;
    justify-content: center;
  }

  .overview__section1-data {
    grid-template-columns: 500px;
    justify-content: center;
  }
}

@media screen and (min-width: 767px) {
  .hero {
    border-bottom-left-radius: 52px;
    border-bottom-right-radius: 52px;
  }
  .hero__image {
    width: 420px;
  }

  .section4__card-data {
    font-size: 1.5rem;
  }

  .works__content {
    grid-template-columns: repeat(2, 280px);
    gap: 32px;
  }

  .overview__section1 {
    row-gap: 6rem;
  }

  .overview__section1-content {
    grid-template-columns: 400px 250px;
    align-items: center;
    column-gap: 2rem;
    justify-content: center;
  }

  .section1__tab {
    padding: 1rem 2.5rem;
  }

  .section1__tab-title {
    font-size: 1rem;
  }

  .section1__tab-categorie {
    font-size: 0.75rem;
  }

  .overview__section1-image {
    height: 380px;
  }

  .overview__section1-image img {
    width: 350px;
    bottom: -1rem;
  }

  .overview__section3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .section4__small-cards {
    display: flex;
    flex-direction: column !important;
  }

  .section4__content {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }

  .section4__card-flex {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }

  .section4__card-flex .section4__card-image {
    position: relative;
    height: 238px;
  }

  .section4__card-flex img {
    height: 100%;
    object-fit: cover;
  }

  .card-rigth {
    width: 550px;
  }

  .section4__card-user img {
    width: 32px;
  }

  .section4__card-data {
    font-size: 0.45rem;
  }

  .footer__infos {
    margin-bottom: 4rem;
  }

  .footer__content {
    grid-template-columns: repeat(3, 1fr);
  }

  .section2__rate {
    margin-bottom: 3.5rem;
  }

  .section2__card {
    width: 320px;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .hero {
    height: 120vh;
  }

  .nav {
    height: calc(var(--header-height) + 1rem);
  }

  .nav__toggle,
  .nav__close,
  .menu-button {
    display: none;
  }

  .header-button {
    display: block;
  }

  .nav__list {
    flex-direction: row;
    margin-bottom: 0;
    column-gap: 3rem;
  }

  .hero__container {
    grid-template-columns: 700px;
  }

  .hero__image {
    width: 600px;
  }

  .hero__form {
    width: 380px;
    display: inline-flex;
    margin: 0 auto;
  }

  .explore__content {
    grid-template-columns: 320px 420px;
    gap: 2rem;
  }

  .explore__tabs {
    display: none;
    pointer-events: none;
    visibility: hidden;
  }

  .explore__content-tabs {
    display: initial;
  }

  .explore__image {
    order: 2;
    width: 420px;
  }

  .explore__data {
    grid-template-columns: 500px;
  }

  .overview__section1-data {
    grid-template-columns: 600px;
  }
}

@media screen and (min-width: 1192px) {
  .container {
    margin-inline: auto;
  }

  .section {
    padding-block: 7rem 2rem;
  }

  .hero__logos {
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    column-gap: 5rem;
  }

  .hero {
    height: 140vh;
  }

  .hero__logo {
    width: 150px;
  }

  .hero__container {
    grid-template-columns: 880px;
    padding-top: 9rem;
    row-gap: 4rem;
  }

  .hero .hero__image {
    width: 900px;
  }

  .hero__data {
    grid-template-columns: 700px;
    justify-content: center;
  }

  .hero__description {
    margin-bottom: 3.5rem;
    line-height: 24px;
  }

  .explore__content-tabs {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    z-index: var(--z-tooltip);
  }

  .explore__container {
    position: relative;
  }

  .explore__content {
    grid-template-columns: 450px 690px;
    align-items: flex-start;
  }

  .explore__image {
    position: relative;
    width: 690px;
    height: 520px;
    border-radius: 32px;
    overflow: hidden;
  }

  .explore__image img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .explore__data {
    margin-bottom: 3.5rem;
    grid-template-columns: 600px;
  }

  .works__content {
    grid-template-columns: repeat(3, 280px);
  }

  .works__data {
    margin-bottom: 3.5rem;
    grid-template-columns: 500px;
    justify-content: center;
  }

  .overview__section1-data {
    grid-template-columns: 700px;
  }

  .overview__section1-content {
    grid-template-columns: 880px 200px;
    column-gap: 6rem;
    align-items: center;
  }

  .section1__tabs {
    order: initial;
  }

  .section1__content-grid {
    order: initial;
  }

  .overview-1-decoration {
    position: absolute;
    width: 220px !important;
    right: 2rem;
    top: 5rem;
  }

  .overview-2-image {
    width: 700px !important;
  }

  .overview-3-image {
    width: 700px !important;
  }

  .overview-4-image {
    width: 700px !important;
  }

  .section2__card {
    width: 700px;
    height: 230px;
  }

  .overview__section3 {
    padding: 2.5rem;
  }

  .section3__chart img {
    width: 330px;
  }

  .overview__section3 {
    grid-template-columns: repeat(2, 500px);
  }

  .section3__data-buttons {
    flex-direction: row;
    align-items: center;
    column-gap: 2rem;
    width: 400px;
  }

  .footer__content {
    grid-template-columns: repeat(4, 1fr);
  }

  .footer__infos {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .overview {
    border-radius: 80px;
  }

  .hero {
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
  }

  .section4__card-data {
    color: #a5b3c5;
    font-size: 1rem;
  }

  .section4__card-flex {
    height: 238px;
  }

  .section4__small-cards {
    margin-bottom: 0;
  }
  
}

@media screen and (min-height: 932px) {
  .hero {
    height: 110vh;
  }
}

/* @media screen and (max-height: 932px) {
  .hero {
    height: 90vh;
  }
} */

@media screen and (min-height: 1080px) { 
  .hero {
    height: 95vh;
  }

  .hero__logos {
    column-gap: 5rem;
  }

  .hero__container {
    grid-template-columns: 900px;
    padding-top: 9rem;
    row-gap: 5rem;
  }
}
